import React from 'react';
import Header from '../../../Header';
import Instructions from './Instructions';
import About from './About';
import Video from './Video';
import Overview from './Overview';

const MyMemoria = () => {
  return(
    <div>
      <div className='ui container'>
        <Header currentPage='mymem' />
      </div>
      <div>
        <h1>My Memoria: John 1</h1> - an educational app to help memorize John chapter 1 in english and Latin.
      </div>
      <Overview />
      <Instructions />
      <Video />
      <About />
    </div>
  )
}

export default MyMemoria;
