import React from 'react'
import styles from './body.module.css'

const About = () => {
  return(
    <div className={styles.aboutContainer}>
      <div className={styles.centeredDiv}>
        <div className={styles.sectionContainer}>
          <div className={styles.sectionTitle}>Purpose</div>
          <div className={[styles.sectionListItem, styles.marginLeft5].join(" ")}>Provide free education</div>
          <div className={[styles.sectionListItem, styles.marginLeft5].join(" ")}>Offer Coaching and Training</div>
        </div>
        <div className={styles.sectionContainer}>
          <div className={styles.sectionTitle}>Education</div>
          <div className={[styles.sectionSubTitle, styles.marginLeft5].join(' ')}>
            <div className={styles.universityName}>University of Mary Hardin-Baylor</div>
          </div>
          <div className={[styles.sectionText, styles.marginLeft10].join(" ")}>
            <div className={styles.year}>2012-2013</div>
            <div className={styles.degree}>No degree earned</div>
            <div className={styles.major}>Major</div>
            <div className={styles.city}>Belton, TX</div>
          </div>
          <div className={[styles.sectionSubTitle, styles.marginLeft5].join(' ')}>
            <div className={styles.universityName}>Southwestern Baptist Theological Seminary</div>
          </div>
          <div className={[styles.sectionText, styles.marginLeft10].join(" ")}>
            <div className={styles.year}>2000-2004</div>
            <div className={styles.degree}>M.Div</div>
            <div className={styles.major}>Major</div>
            <div className={styles.city}>Fort Worth, TX</div>
          </div>
          <div className={[styles.sectionSubTitle, styles.marginLeft5].join(" ")}>
            <div className={styles.universityName}>Texas A&M University</div>
          </div>
          <div className={[styles.sectionText, styles.marginLeft10].join(" ")}>
            <div className={styles.year}>1992-1999</div>
            <div className={styles.degree}>BS</div>
            <div className={styles.major}>Kinesiology</div>
            <div className={styles.city}>College Station, TX</div>
          </div>
          <div className={[styles.sectionSubTitle, styles.marginLeft5].join(" ")}>
            <div className={styles.universityName}>Kilgore High School</div>
          </div>
          <div className={[styles.sectionText, styles.marginLeft10].join(" ")}>
            <div className={styles.year}>1989-1992</div>
            <div className={styles.degree}>Degree</div>
            <div className={styles.major}>Major</div>
            <div className={styles.city}>Kilgore, TX</div>
          </div>
        </div>
        <div className={styles.sectionContainer}>
          <div className={styles.sectionTitle}>Experience</div>
        </div>
        <div className={styles.sectionContainer}>
          <div className={styles.sectionTitle}>Testimony</div>
        </div>
      </div>
    </div>
  )
}

export default About;
