import React from 'react';

class Overview extends React.Component {
  render() {
    return(
      <div>
        <h1>
          Overview
        </h1>
        <div>
          My Memoria: John 1 is designed to help a student the first chapter of John in English and Latin. This app uses the King James Version for the english and the Latin Vulgate for the Latin. Future realeases will include the entire book of John.
        </div>
        <div>
          This app currently has one game for the student to play.
          <div>
            The game is played by selecting the correct word to fill the blank(s).
          </div>
        </div>
      </div>
    );
  }
}

export default Overview;
