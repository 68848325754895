import React from 'react';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import Header from './Header/Header';
import Body from './Body/Body';
import Footer from './Footer/Footer';
import Coaching from './Coaching/Coaching';
import Financial from './Coaching/Financial/Financial';
import FinancialCoaching from './Coaching/Financial/Coaching';
import Consultation from './Coaching/Financial/Consultation';
import Expectations from './Coaching/Financial/Expectations';
import Clients from './Coaching/Financial/Clients';
import Articles from './Coaching/Financial/Articles';
import Bio from './Coaching/Financial/Bio';
import FiveKnowsMain from './Coaching/FiveKnows/Main/Main';
import About from './Coaching/FiveKnows/body/about';
import Coach from './Coaching/FiveKnows/body/coach';
import Contact from './Coaching/FiveKnows/body/contact';
import Present from './Coaching/FiveKnows/body/present';

import MobileApps from './Business/mobile/MobileApps';
import SitSA from './Business/mobile/sitsa/SitSA';
import LatinTutorGameDeclensions from './Business/mobile/sitsa/ltgdeclensions/LatinTutorGameDeclensions';
import MyMemoria from './Business/mobile/sitsa/mymemoria/MyMemoria';
import UpTo15s from './Business/mobile/sitsa/upto15s/UpTo15s';
import PrivacyPolicy from './Business/mobile/sitsa/PrivacyPolicy';
import TermsAndConditions from './Business/mobile/sitsa/TermsAndConditions';

import './App.css';


function App() {
	return (
    <BrowserRouter>
    <div className="main-content">
      <div className="App">
        <Header />
          <Routes>
            <Route path="/*" element={<Body />} >
							<Route path='' element={<div>  </div>} />
							<Route path='business/*' element={<MobileApps />} >
							</Route>
							<Route path='coaching/*' element={<Coaching />} >
		            <Route path='financial/*' element={<Financial />} >
									<Route path='' element={<FinancialCoaching />} />
		              <Route path='consultation' element={<Consultation />} />
		              <Route path='expectations' element={<Expectations />} />
		              <Route path='clients' element={<Clients />} />
		              <Route path='articles' element={<Articles />} />
		              <Route path='bio' element={<Bio />} />
		            </Route>
								<Route path='fiveknows/*' element={<FiveKnowsMain />} >
									<Route path='coach' element={<Coach />} />
									<Route path='present' element={<Present />} />
									<Route path='contact' element={<Contact />} />
									<Route path='about' element={<Bio />} />
								</Route>
							</Route>
							<Route path='mobile/*'  element={<MobileApps />} >
		            <Route path='sitsa/*'  element={<SitSA />} >
			            <Route path='ltgdeclensions' element={<LatinTutorGameDeclensions />} />
			            <Route path='mymemoria' element={<MyMemoria />} />
			            <Route path='upto15s' element={<UpTo15s />} />
			            <Route path='PrivacyPolicy'  element={<PrivacyPolicy />} />
									<Route path='TermsAndConditions'  element={<TermsAndConditions />} />
								</Route>
							</Route>
						</Route>
          </Routes>
        <Footer />
      </div>
    </div>
    </BrowserRouter>
  );
}

export default App;

//
// <Route path='fiveknows/*' element={<Outlet />} >
// 	<Route path='' element={<FiveKnowsMain />} />
