import React from 'react';
import './Footer.css';
import logo from '../Logo/jml_logo.svg';

export default function FooterLogo(){
	return(
		<div className='footer-logo'>
			<img src={logo} />
		</div>
	);
}
