import React from 'react'
import styles from './body.module.css'

const Present = () => {
  return(
    <div className={styles.presentContainer}>
      30-120 minute presentation: cost of travel and lodging outside of 30 minute drive<br />
      2-day seminar $2,500 within USA $3,500 outside USA<br />
      5-day seminar $4,500 within USA $5,500 outside USA<br />
    </div>
  )
}

export default Present;
