import React from 'react';

class Instructions extends React.Component {
  render() {
    return(
      <div>
        <h1>
          Instructions
        </h1>
        <div>
          This is how you play each of the games and navigate the application.
          This will include instructions for user management within the app.
        </div>
      </div>
    );
  }
}

export default Instructions;
