import React from 'react';
import './Footer.css';

export default function FooterCopyright(){
	return(
		<div className='footer-copyright'>
			JMLatham LLC &#169; {new Date().getFullYear()}
		</div>
	);
}
