import React, { useState } from 'react'
import styles from './knows.module.css'
// import importScript from '../../utilities/importScript';
// import { alertMessage } from '../../scripts/test.js';

const Message = () => {
  // importScript("/scripts/test.js");
  // alertMessage("hello")
  const [hide, setHide] = useState(styles.hide);
  const [arrowDirection, setArrowDirection] = useState(styles.right);
  const handleClick = event => {
    if(hide == ""){
      setHide(styles.hide);
    } else {
      setHide("");
    }
    if(arrowDirection == styles.right){
      setArrowDirection(styles.down);
    } else {
      setArrowDirection(styles.right);
    }
  };

  return(
    <div className={styles.message}>
      <div className={[styles.title, styles.hand].join(' ')} onClick={handleClick}>KNOW Your Message <i className={[styles.arrow, arrowDirection].join(' ')}></i> </div>
      <div className={[styles.body, hide].join(' ')} >
        <ul className={styles.listTitle}>Know what you are going to say
          <li className={styles.listItem}>Identify your topic</li>
          <li className={styles.listItem}>Organize your topic</li>
          <li className={styles.listItem}>Prove your topic: facts & figures</li>
          <li className={styles.listItem}>Demonstrate your topic: what other people have said</li>
          <li className={styles.listItem}>Narrow the length of your presentation</li>
        </ul>
        <ul className={styles.listTitle}>Know why you need to speak
          <li className={styles.listItem}>Identify your purpose</li>
          <li className={styles.listItem}>Incorporate your purpose (make it practical)</li>
        </ul>
      </div>
    </div>
  )
}

export default Message
