import React from 'react'
import MenuItem from './menuItem'

const Menu = ({ divClassMenu, divClassMenuItem, divClassMenuItemContainer }) => {
  function buildMenu(items){
    var listItems = [];

    for (var key in items) {
      if (items.hasOwnProperty(key)) {
        listItems.push(<MenuItem divClass={divClassMenuItem} text={key} path={items[key]} />);
      }
    }

    return (
      <div className={divClassMenuItemContainer}>
        {listItems}
      </div>
    )
  }

  return(
    <div className={divClassMenu}>
      {buildMenu({"Home":"/coaching/fiveknows","Coaching":"/coaching/fiveknows/coach","Presentations":"/coaching/fiveknows/present","Contact Me":"/coaching/fiveknows/contact","About Me":"/coaching/fiveknows/about"})}
    </div>
  );
}

export default Menu;
