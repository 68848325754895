import React from 'react';
import Logo from '../Logo/Logo';
import HeaderTitle from './HeaderTitle';
import './Header.css';

function Header() {
	return (
		<div className="header-container">
			<Logo
				containerClass="header-logo-container"
				imgClass="header-logo"
				nameClass="header-company-name"/>
			<HeaderTitle title={["Welcome to ", <br />,"JMLatham, LLC"]} />
		</div>
	);
}

export default Header;
