import React from 'react';

const Clients = () => {
  return (
    <div className='clients-container'>
      <div className='clients-title'>Ideal Clients</div>
      <div className='verticalSpacer20px'></div>
			<div className='clients-message'>My ideal clients are the people who are ready to make transformational changes in their lives in order to achieve the desired results and reach success.<br />I am looking for people who know that their current situation is not good and are on a path that leads to ruin.<br />I am looking for the people who need and want my help.</div>
      <div className='verticalSpacer20px'></div>
      <div className='verticalSpacer20px'></div>
      <div className='verticalSpacer20px'></div>
    </div>
  );
}

export default Clients;
