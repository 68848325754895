import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Footer.css';

export default function FooterMenu(){
  const navigate = useNavigate();

  const handleClick = (path) => {
    navigate(path);
  }

	return(
		<div className='footer-menu'>
			<div className='footer-menu-button gradient' onClick={() => handleClick('/')}>Home</div>
			<div className='footer-menu-button gradient' onClick={() => handleClick('about')}>About Us</div>
			<div className='footer-menu-button gradient' onClick={() => handleClick('contact')}>Contact Us</div>
		</div>
	);
}
