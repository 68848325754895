import React from 'react'
import Audience from './knows/audience'
import Environment from './knows/environment'
import Media from './knows/media'
import Message from './knows/message'
import Yourself from './knows/yourself'
import styles from './body.module.css'

const Home = () => {
  return(
    <div className={styles.homeContainer}>
      <div className={styles.centeredDiv}>
        <Message />
        <Audience />
        <Environment />
        <Media />
        <Yourself />
      </div>
    </div>
  )
}

export default Home;
