import React from 'react'
import MailingList from './mailingList'
import ContactInfo from '../components/contactInfo'
import styles from './footer.module.css'

const Footer = ({ siteInfo }) => {
  return(
    <div className={styles.footer}>
      <MailingList />
      <ContactInfo
        siteInfo={siteInfo}
        divClassContainer={styles.contactInfoContainer}
        divClassTitle={styles.contactInfoTitle}
        divClassPhone={styles.contactInfoPhone}
        divClassEmail={styles.contactInfoEmail}
        divClassFacebook={styles.contactInfoFacebook}
      />
    </div>
  );
}

export default Footer;
