import React from 'react';

class Overview extends React.Component {
  render() {
    return(
      <div className='section'>
        <div className='title'>
          Overview
        </div>
        <div className='subtitle'>
          Latin Tutor Game: Declensions is designed to help a student learn about Latin declensions for the nouns and adjectives.
        </div>
        <div className='text'>
          This app has three games for the student to play.
          <div className='list'>
            <div className='item'>
              The first game is a chart game where the player fills the declension ending chart with the correct letters.
            </div>
            <div className='item'>
              The second game reviews the vocabulary words in such a way the user has to select the correct declension for the displayed word.
            </div>
            <div className='item'>
              The third game is a matching game that enforces the usage of the cases associated with each declension.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Overview;
