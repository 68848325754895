import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from "../header/header"
import Footer from "../footer/footer"
import Home from '../body/home';
// import styles from '../App.css';

export default function Main() {
	const location = useLocation();

	let returnObj = <Outlet />
	if(location.pathname === '/coaching/fiveknows'){
		returnObj = <Home />
	}

	return(
		<div className=''>
			<Header  />
			{returnObj}
			<Footer />
		</div>
	);
}
