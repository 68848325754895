import React from 'react';
import { Link } from 'react-router-dom';


class Header extends React.Component {
  render()
  {
    if(!this.props.currentPage){
      return <div> </div>
    }
    switch(this.props.currentPage)
    {
      case 'main':
        return(
          <div className='nav-container'>
          <div className='navigation'>
          <Link to='/' className='nav-item'>
            Home
          </Link>
          <Link to='/coaching/' className='nav-item'>
            Financial Coaching
          </Link>
          <Link to='/speaking/' className='nav-item'>
            Public Speacking Coaching
          </Link>
          <Link to='/coaching/Bio' className='nav-item'>
            About Me
          </Link>
          </div>
          </div>
        );

      case 'mobile':
      return(
        <div className='navigation'>
          <Link to='/mobile/sitsa' className='nav-item'>
            Stick in the Sand Apps
          </Link>
        </div>
      );

      case 'web':
      return(
        null
      );

      case 'sitsa':
      return(
        <div className='navigation'>
          <div>
            Stick In the Sand Apps
          </div>
          <Link to='/mobile/sitsa/PrivacyPolicy/' className='nav-item'>
            Privacy Policy
          </Link>
          <Link to='/mobile/sitsa/mymemoria' className='nav-item'>
            My Memoria: John 1
          </Link>
          <Link to='/mobile/sitsa/ltgdeclensions' className='nav-item'>
            Latin Tutor Game: Declensions
          </Link>
          <Link to='/mobile/sitsa/upto15s' className='nav-item'>
            Up To 15s
          </Link>
        </div>
      );

      case 'ltgd':
      return(
        <div className='navigation'>
          <Link to='/mobile/sitsa' className='nav-item'>
            Stick In the Sand Apps
          </Link>
          <Link to='/mobile/sitsa/PrivacyPolicy/' className='nav-item'>
            Privacy Policy
          </Link>
          <Link to='/mobile/sitsa/mymemoria' className='nav-item'>
            My Memoria: John 1
          </Link>
          <div>
            Latin Tutor Game: Declensions
          </div>
          <Link to='/mobile/sitsa/upto15s' className='nav-item'>
            Up To 15s
          </Link>
        </div>
      );

      case 'mymem':
      return(
        <div className='navigation'>
          <Link to='/mobile/sitsa' className='nav-item'>
            Stick In the Sand Apps
          </Link>
          <Link to='/mobile/sitsa/PrivacyPolicy/' className='nav-item'>
            Privacy Policy
          </Link>
          <div>
            My Memoria: John 1
          </div>
          <Link to='/mobile/sitsa/ltgdeclensions' className='nav-item'>
            Latin Tutor Game: Declensions
          </Link>
          <Link to='/mobile/sitsa/upto15s' className='nav-item'>
            Up To 15s
          </Link>
        </div>
      );

      case 'upto15s':
      return(
        <div className='navigation'>
          <Link to='/mobile/sitsa' className='nav-item'>
            Stick In the Sand Apps
          </Link>
          <Link to='/mobile/sitsa/PrivacyPolicy/' className='nav-item'>
            Privacy Policy
          </Link>
          <Link to='/mobile/sitsa/mymemoria' className='nav-item'>
            My Memoria: John 1
          </Link>
          <Link to='/mobile/sitsa/ltgdeclensions' className='nav-item'>
            Latin Tutor Game: Declensions
          </Link>
          <div >
            Up To 15s
          </div>
        </div>
      );

      case 'other':
      return(
        null
      );

      case 'sitsaPP':
      return(
        <div className='navigation'>
          <Link to='/mobile/sitsa' className='nav-item'>
            Stick in the Sand Apps
          </Link>
          <div>
            Privacy Policy
          </div>
          <Link to='/mobile/sitsa/mymemoria' className='nav-item'>
            My Memoria: John 1
          </Link>
          <Link to='/mobile/sitsa/ltgdeclensions' className='nav-item'>
            Latin Tutor Game: Declensions
          </Link>
          <Link to='/mobile/sitsa/upto15s' className='nav-item'>
            Up To 15s
          </Link>
        </div>
      );

      default:
      return(
        <div className='navigation'>

        </div>
      );
    }
  }
};



export default Header;
