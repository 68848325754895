import React from 'react'
import TitleIcon from './titleIcon'
import TitleText from './titleText'
import styles from './header.module.css'


const Title = () => {
  return(
    <div className={styles.headerTitleContainer}>
      <TitleIcon iconPath=">>>---->" style={styles.headerTitleIcon} />
      <TitleText text="Five Knows of Public Speaking" />
      <TitleIcon iconPath="<----<<<" style={styles.headerTitleIcon} />
    </div>
  );
}

export default Title;
