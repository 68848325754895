import React from 'react';
import { Link } from 'react-router-dom';
import MainPageTitle from './MainPageTitle';
import ClientDesire from './ClientDesire';
import Values from './Values';
import PositiveResults from './PositiveResults';
import NegativeResults from './NegativeResults';
import ThePlan from './ThePlan';
import ThePDF from './ThePDF';


class MainBodyContainer extends React.Component {
  render() {
  return(
    <div className='main-body-container'>
      <MainPageTitle />
      <div className='verticalSpacer20px'>  </div>
      <div className='verticalSpacer20px'>  </div>
      <div className='verticalSpacer20px'>  </div>
      <div className='verticalSpacer20px'>  </div>
      <ClientDesire />
      <div className='verticalSpacer20px'>  </div>
      <div className='verticalSpacer20px'>  </div>
      <div className='verticalSpacer20px'>  </div>
      <div className='verticalSpacer20px'>  </div>
      <Values />
      <div className='verticalSpacer20px'>  </div>
      <div className='verticalSpacer20px'>  </div>
      <div className='verticalSpacer20px'>  </div>
      <div className='verticalSpacer20px'>  </div>
      <PositiveResults />
      <div className='verticalSpacer20px'>  </div>
      <NegativeResults />
      <div className='verticalSpacer20px'>  </div>
      <div className='verticalSpacer20px'>  </div>
      <div className='verticalSpacer20px'>  </div>
      <div className='verticalSpacer20px'>  </div>
      <ThePlan />
      <div className='verticalSpacer20px'>  </div>
      {/*<ThePDF />*/}
      <div className='verticalSpacer20px'>  </div>
    {/*
      <div>
        <Link to={{ pathname: "http://www.blueletterbible.com" }} target="_blank" className='schedule-button'>
          schedule btn
        </Link>
      </div>
    */}
      <div className='verticalSpacer20px'>  </div>
      <div className='verticalSpacer20px'>  </div>
      <div className='verticalSpacer20px'>  </div>
      <div className='verticalSpacer20px'>  </div>
    </div>
  );
  }
}

export default MainBodyContainer;
