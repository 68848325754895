import React from 'react';
import FooterLogo from './FooterLogo';
import FooterMenu from './FooterMenu';
import FooterCopyright from './FooterCopyright';
import './Footer.css';

function Footer() {
	return (
		<div className='footer-container'>
			<FooterMenu />
			<div style={{flex:1, display:'flex', flexDirection:'column'}}>
				<FooterCopyright />
				<FooterLogo />
			</div>
		</div>
	);
}

export default Footer;
