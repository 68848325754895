import React from 'react';

class Video extends React.Component {
  render() {
    return(
      <div className='section'>
        <div className='title'>
          Video
        </div>
        <div className='text'>
        </div>
      </div>
    );
  }
}

export default Video;
