import React from 'react';
import { Outlet } from 'react-router-dom';
import CoachingHeader from './sections/Header';

export default function Financial() {
	return(
		<>
		<CoachingHeader currentPage="coaching" />
		<Outlet />
		</>
	);
}
