import React from 'react'
import styles from './header.module.css'

const TitleIcon = ({ iconPath, style }) => {
  return(
    <div className={[styles.headerTitleIcon, style].join(' ')}>
      {/*iconPath*/}
      <svg width="45" height="34.6" x="0" y="0">
        <path className={styles.hexStyle}  d="M1,17.3 l10,17.3 l20,0 l10,-17.3 l-10,-17.3 l-20,0 z" />
      </svg>
    </div>
  );
}

export default TitleIcon;
