import React from 'react';
import SitSAMission from '../SitSAMission';

class About extends React.Component {
  render() {
    return(
      <div className='section'>
        <div className='title'>
          About
        </div>
        <div className='text'>
          Latin Tutor Game: Declensions has been coded using Swift for iOS and Java for Android. It was coded by Stick in the Sand Apps (SitSA).
          <SitSAMission />
        </div>
      </div>
    );
  }
}

export default About;
