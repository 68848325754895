import React, { useState } from 'react'
import styles from './knows.module.css'

const Audience = () => {
  const [hide, setHide] = useState(styles.hide);
  const [arrowDirection, setArrowDirection] = useState(styles.right);
  const handleClick = event => {
    if(hide == ""){
      setHide(styles.hide);
    } else {
      setHide("");
    }
    if(arrowDirection == styles.right){
      setArrowDirection(styles.down);
    } else {
      setArrowDirection(styles.right);
    }
  };

  return(
    <div className={styles.audience}>
      <div className={[styles.title, styles.hand].join(' ')} onClick={handleClick}>KNOW Your Audience <i className={[styles.arrow, arrowDirection].join(' ')}></i> </div>
      <div className={[styles.body, hide].join(' ')}>
        <ul className={styles.listTitle}>
          <li className={styles.listItem}>
            Know where they come from
            <ul className={styles.subListTitle}>
              <li className={styles.subListItem}>Culture</li>
              <li className={styles.subListItem}>Profession</li>
              <li className={styles.subListItem}>...</li>
            </ul>
          </li>
          <li className={styles.listItem}>Know why they are there</li>
        </ul>
      </div>
    </div>
  )
}

export default Audience
