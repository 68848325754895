import React from 'react';
import { Link } from 'react-router-dom';
import ScheduleButton from './ScheduleButton';

const ClientDesire = () => {
  return (
		<div className='what-client-wants-container'>
			<div className='what-client-wants-message'>
				<span className='check-mark'>&#10004;</span>Are you tired of being broke?&nbsp;&nbsp;
				<span className='check-mark'>&#10004;</span>Are you living paycheck to paycheck?&nbsp;&nbsp;
        <span className='check-mark'>&#10004;</span>Are your relationships suffering?&nbsp;&nbsp;
        <span className='check-mark'>&#10004;</span>Are you ready to change?
				<br /><br /><br />
				Would you like money to work for you
				<br />
				instead of you working for money?
			</div>
	    <div className='verticalSpacer20px'>  </div>
			<ScheduleButton />
		</div>
  );
}

export default ClientDesire;
