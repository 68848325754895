import React from 'react';

class Video extends React.Component {
  render() {
    return(
      <div>
        <h1>
          Video
        </h1>
      </div>
    );
  }
}

export default Video;
