import React from 'react';

class SitSAMission extends React.Component {
  render() {
    return(
      <div>
        The Mission of Stick in the Sand Apps (SitSA) is to build fun yet simple educational games to help reinforce student learning.
        The plan is to try to keep the games as simple as a stick in the sand.
        There are no advertisements or in-app purchases, so you get everything provided by the game without being required to purchase anything else.
        The content is safe for people of all ages.
        Some games will be designed to unlock content based on experience level, while others will not have any locked content.
        With the usermanagement within the games, a person's device can hold information for multiple users, and all information collected by the app will be kept safe on the device.
        
      </div>
    );
  }
}

export default SitSAMission;
