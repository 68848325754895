import React, { useState } from 'react'
import MailingListTitle from './mailingListTitle'
import TextInput from '../components/textInput'
import Button from '../components/button'
import styles from './mailingList.module.css'
// import axios from 'axios';

const MailingList = () => {
  // axios.defaults.baseURL = 'http://localhost:3100';
  const [inputValue, setInputValue] = useState('');
  const handleInputChange = event => setInputValue(event.target.value);
  const handleButtonClick = event => {
    // axios.post('/api/users', {
    //   email: inputValue
    // })
    // .then(function (response) {
    //   alert('Thank You for signing up!');
    //   setInputValue("");
    // })
    // .catch(function (error) {
    //   if (error.response) {
    //     // Request made and server responded
    //     const {data, status, headers} = error.response;
    //     if(data.message === "duplicate key error" && status === 422){
    //       alert(data.results.email + ' has already signed up. \nWe do, however, appreciate you trying again.');
    //       setInputValue("");
    //     } else {
    //       console.log("---mailingList---");
    //       console.log("DATA:", error.response.data);
    //       console.log("STATUS:", error.response.status);
    //       console.log("HEADERS:", error.response.headers);
    //     }
    //   } else if (error.request) {
    //     // The request was made but no response was received
    //     console.log("---mailingList---\n", error.request);
    //   } else {
    //     // Something happened in setting up the request that triggered an Error
    //     console.log('Error', error.message);
    //   }
    // });
  }
  return(<></>);
  // return(
  //   <div className={styles.mailingList}>
  //     <MailingListTitle
  //       divClass={styles.mailingListTitle}
  //       titleText="Mailing List Signup" />
  //     <TextInput
  //       divClassLabel={styles.mailingListTextInputLabel}
  //       divClassInput={styles.mailingListTextInput}
  //       value={inputValue}
  //       onChangeInput={handleInputChange}
  //       children="Enter your email address:" />
  //     <Button
  //       divClassButtonContainer={styles.mailingListButtonContainer}
  //       divClassButton={styles.mailingListButton}
  //       onButtonClicked={handleButtonClick}
  //       children="Sign Up" />
  //   </div>
  // );
}

export default MailingList;
