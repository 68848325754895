import React from 'react'
import styles from './body.module.css'

const Contact = () => {
  return(
    <div className={styles.contactContainer}>
      Email: marshall@jmlatham.com <br />
      Phone #: (254) 681-0745
      FaceBook: (facebook account)
    </div>
  )
}

export default Contact;
