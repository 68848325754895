import React from 'react';
import { Link } from 'react-router-dom';


class Header extends React.Component {
  render()
  {
    if(!this.props.currentPage){
      return <div> </div>
    }
    switch(this.props.currentPage)
    {
      case 'coaching':
        return(
          <div className='nav-container'>
          <div className='navigation'>
          <Link to='/' className='nav-item'>
            Home
          </Link>
          <Link to='/coaching/financial/consultation/' className='nav-item'>
            Schedule Consultation
          </Link>
          <Link to='/coaching/financial/expectations' className='nav-item'>
            Coaching Expectations
          </Link>
          <Link to='/coaching/financial/clients' className='nav-item'>
            Ideal Clients
          </Link>
          <Link to='/coaching/financial/articles' className='nav-item'>
            Articles
          </Link>
          <Link to='/coaching/financial/bio' className='nav-item'>
            About Me
          </Link>
          </div>
          </div>
        );

      default:
      return(
        <div className='navigation'>

        </div>
      );
    }
  }
};



export default Header;
