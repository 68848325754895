import React from 'react';
import { Link } from 'react-router-dom';
import ScheduleButton from './ScheduleButton';

const MainPageTitle = () => {
  return (
		<div className='title'>
			<div className='floatRight'>
				<ScheduleButton />
			</div>
			<div className='main-page-title'>Transformational Financial Coaching</div>
		</div>
  );
}

export default MainPageTitle;
