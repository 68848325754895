import React from 'react'
import styles from './header.module.css'

const TitleText = ({ text }) => {
  return(
    <div className={styles.headerTitleText}>
      {text}
    </div>
  );
}

export default TitleText;
