import React from 'react';
import { Link } from 'react-router-dom';

const ScheduleButton = () => {
  return (
		<div className='schedule-button-container'>
			<Link to={{ pathname: "https://calendly.com/jmlatham/30min" }} target="_blank" className='schedule-button'>
				Schedule a Consultation!
			</Link>
		</div>
	);
}

export default ScheduleButton;


{/*
  <div>
    <Link to={{ pathname: "https://calendly.com/jmlatham/30min" }} target="_blank" className='schedule-button'>
      schedule btn
    </Link>
  </div>
  https://calendly.com/jmlatham/30min
*/}
