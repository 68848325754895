import React from 'react';
import SitSAMission from '../SitSAMission';

class About extends React.Component {
  render() {
    return(
      <div>
        <h1>
          About
        </h1>
        <div>
          My Memoria: John 1 has been coded using Swift for iOS and Java for Android. It was coded by Stick in the Sand Apps (SitSA).
          <SitSAMission />
        </div>
      </div>
    );
  }
}

export default About;
