import React from 'react';

const Expectations = () => {
  return (
    <div className='expectations-container'>
      <div className='expectations-title'>Expectations</div>
			<div className='expectations-client'>My client can expect...
        <div>
          <ul>
            <li>that I will coach based on what is best for my client </li>
            <li>that I will remain in communication with my client </li>
            <li>that I will be prompt barring extenuating circumstances </li>
            <li>that I will do due diligence in my research of my client's situation and needs </li>
            <li>that I will keep my client's business private </li>
            <li>that I will pray for my client </li>
            <li>that I will push my client to make the best decisions for my client and client's family</li>
            <li>that I will care about my client and client's family beyond finances</li>
            <li>that I will teach my client principles of success that will transform my client's <br />and my client's family's lives for the best</li>
            <li>that I will never suggest or lead my client to do anything illegal, immoral, or unethical</li>
            <li>that I will be honest with my client</li>
          </ul>
        </div>
      </div>
			<div className='expectations-coach'>As coach I expect...
        <div>
          <ul>
            <li>my client to be honest with me </li>
            <li>my client to be faithful (keep commitments) </li>
            <li>my client to be available (prioritize coaching sessions) </li>
            <li>my client to be teachable (hungry to learn and grow) </li>
            <li>my client to communicate with me </li>
            <li>my client to avoid doing things that are illegal, immoral, and unethical </li>
          </ul>
        </div>
      </div>
      <div className='verticalSpacer20px'></div>
      <div className='verticalSpacer20px'></div>
    </div>
  );
}

export default Expectations;
