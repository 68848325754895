import React from 'react'
import { NavLink } from 'react-router-dom';


const MenuItem = ({ divClass, text, path }) => {
  return(
    <div className={divClass}>
      <NavLink to={path}>{text}</NavLink>
    </div>
  );
}

export default MenuItem;
