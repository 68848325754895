import React from 'react';
import Header from '../../../Header';
import Instructions from './Instructions';
import About from './About';
import Video from './Video';
import Overview from './Overview';

const LatinTutorGameDeclensions = () => {
  return(
    <div className='page'>
      <div className='navigation'>
        <Header currentPage='ltgd' />
      </div>
      <div className='heading'>
        <div className='title'>Latin Tutor Game: Declensions</div>
        <div className='subtitle'> - an educational app to help learn Latin Declensions</div>
      </div>
      <div className='content'>
        <Overview />
        <Instructions />
        <Video />
        <About />
      </div>
    </div>
  )
}

export default LatinTutorGameDeclensions;
