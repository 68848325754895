import React from 'react';

const Bio = () => {
  return (
    <div className='bio-container'>
      <div className='bio-title'> About Me </div>
      <div><a href="https://www.linkedin.com/in/j-marshall-latham-57347254" target='_blank' rel="noopener">LinkedIn Profile</a></div>
			<div className='bio-body'>
        <div className='bio-body-story'>
          <p className='section-title'>My Story</p>
          <p className='story-body indent-left'>
          Even though I was brought up with some financial training, I was more concerned about the present when I went off to college. It was more important to me to go to the big state school and get my degree. Instead of staying local and paying for school as I was able, I procured student loans (that I had to work hard to pay back - no loan forgiveness for me). I figured I would get a great paying job that would quickly pay off the loans. The problem is that it set me on a path of using debt to get the things I wanted. I remember how I "needed" a new computer and borrowed money to get one. My wife and I would be able to pay it off quickly with the business we had started. Oh, and we needed a house for our children, so we got ourselves a mortgage. I can't remember right off the other things that we (mainly me) just had to have and used debt to get. That debt was a heavy burden for us. Using Dave Ramsey's Baby Steps, we started to dig out of our debt. We sold the house, and we eventually paid off all the debt including the student loans which took us the longest to pay. Since that time, we have been able to remain debt free except for a new mortgage. We have not bought a brand new car, but the cars we have purchased were bought with cash. Our vacations have been cash. Using our emergency funds that we have saved up, we have been able to remain debt free when things came our way like flat tires and broken appliances. We are currently striving to pay off our mortgage early. We were able to change our lifestyle. You can too.
          </p>
        </div>
        <div className='bio-body-purpose'>
          <p className='section-title'>Purpose</p>
          <div className='indent-left'>
            <p className='purpose-title'>My Purpose for Living is...</p>
            <p className='purpose-body'>1. To bring glory and honor to Christ Jesus, my Lord and Savior, <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;by being who He wants me to be and <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;by doing what He wants me to do</p>
            <p className='purpose-body'>2. To love God with all my heart, soul, mind, strength</p>
            <p className='purpose-body'>3. To love my neighbor as myself</p>
            <p className='purpose-body'>4. To provide for my family and help each member of my family become who Jesus wants them to be</p>
            <p className='purpose-body'>5. To share with people the message of hope and salvation with others and help them as I am able</p>
          </div>
        </div>
        <div className='bio-body-family'>
          <p className='section-title'>Family</p>

          <p className='indent-left'>I have a wife and four children</p>

        </div>
        <div className='bio-body-education'>
          <p className='section-title'>Education</p>
          <div className='indent-left'>
            <p>Highschool Diploma from <a href='https://khs.kisd.org/' target='_blank' rel="noopener">Kilgore Highschool</a> in Kilgore, TX</p>
            <p>Bachelor of Science in Kinesiology from <a href='https://www.tamu.edu/' target='_blank' rel="noopener">Texas A&M University</a> in College Station, TX</p>
            <p>Master of Divinity with Biblical Languages from <a href='https://swbts.edu/' target='_blank' rel="noopener">Southwestern Baptist Theological Seminary</a> in Fort Worth, TX</p>
          </div>
        </div>
        <div className='bio-body-experience'>
          <p className='section-title'>Experience</p>
          <div className='indent-left'>
            <p className='experience-title'>Pastor</p>
            <p className='indent-left'><a href='https://www.caneycreekbaptist.com/' target='_blank' rel="noopener">Caney Creek Baptist Church</a> - Pastor</p>
            <p className='experience-title'>Teacher</p>
            <p className='indent-left'><a href='https://www.kilgore.edu/' target='_blank' rel="noopener">Kilgore College</a> - Adjunct Professor</p>
            <p className='experience-title'>Public Speaker</p>
            <p className='indent-left'>I have had the opportunity to preach in Zambia, Brazil, Moldova, and Honduras</p>
            <p className='indent-left'>I have had the opportunity to speak at several three-day conferences on behalf of <a href='https://classicalconversations.com/' target='_blank' rel="noopener">Classical Conversations</a></p>
            <p className='experience-title'>Business Owner</p>
            <p className='indent-left'>I have built and published a couple of mobile applications:</p> <div className='indent-left'><a href='https://apps.apple.com/us/app/up-to-15s/id1291571270' target='_blank' rel="noopener">Up To 15s</a>, a math app for helping kids learn their math facts and <br /><a href='https://apps.apple.com/gw/app/latin-tutor-game-declensions/id1272994859' target='_blank' rel="noopener">Latin Tutor Game: Declensions</a>, a game designed to help students learn the declension endings for Latin.</div>
            <p className='experience-title'>Systems Analyst/Web Developer</p>
            <p className='indent-left'>I have worked for McLane Co., Lesco Logistics, Southwestern Baptist Theological Seminary, and Network IP.</p>
          </div>
        </div>
      </div>
      <div className='verticalSpacer20px'></div>
      <div className='verticalSpacer20px'></div>
    </div>
  );
}

export default Bio;
