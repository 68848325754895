import React from 'react';
import logo from './jml_logo.svg';
import './Logo.css';


function Logo({ containerClass, imgClass, nameClass }) {
	return(
		<div className={containerClass}>
			<img src={logo} className={imgClass} />
			<div className={nameClass}>JMLatham, LLC</div>
		</div>
	);
}

export default Logo;
