import React from 'react';
import { useNavigate } from 'react-router-dom';
import './MainMenu.css';

export default function MainMenu(){
  const navigate = useNavigate();

  const handleClick = (path) => {
    navigate(path);
  }

	return(
		<div className='main-menu-container'>
			<div className='main-menu-button gradient' onClick={() => handleClick('/')}>Home</div>
			<div className='main-menu-button gradient' onClick={() => handleClick('business')}>Business</div>
			<div className='main-menu-button gradient' onClick={() => handleClick('coaching')}>Coaching</div>
		</div>
	);
}

//
//
// <div className='main-menu-button gradient' onClick={() => handleClick('contact')}>Contact Us</div>
// <div className='main-menu-button gradient' onClick={() => handleClick('/')}>Home</div>
// <div className='main-menu-button gradient' onClick={() => handleClick('about')}>About Us</div>
// <div className='main-menu-button gradient' onClick={() => handleClick('contact')}>Contact Us</div>
// <div className='main-menu-button gradient' onClick={() => handleClick('/')}>Home</div>
// <div className='main-menu-button gradient' onClick={() => handleClick('about')}>About Us</div>
// <div className='main-menu-button gradient' onClick={() => handleClick('contact')}>Contact Us</div>
// <div className='main-menu-button gradient' onClick={() => handleClick('/')}>Home</div>
// <div className='main-menu-button gradient' onClick={() => handleClick('about')}>About Us</div>
// <div className='main-menu-button gradient' onClick={() => handleClick('contact')}>Contact Us</div>
// <div className='main-menu-button gradient' onClick={() => handleClick('/')}>Home</div>
// <div className='main-menu-button gradient' onClick={() => handleClick('about')}>About Us</div>
// <div className='main-menu-button gradient' onClick={() => handleClick('contact')}>Contact Us</div>
// <div className='main-menu-button gradient' onClick={() => handleClick('/')}>Home</div>
// <div className='main-menu-button gradient' onClick={() => handleClick('about')}>About Us</div>
// <div className='main-menu-button gradient' onClick={() => handleClick('contact')}>Contact Us</div>
// <div className='main-menu-button gradient' onClick={() => handleClick('/')}>Home</div>
// <div className='main-menu-button gradient' onClick={() => handleClick('about')}>About Us</div>
// <div className='main-menu-button gradient' onClick={() => handleClick('contact')}>Contact Us</div>
// <div className='main-menu-button gradient' onClick={() => handleClick('/')}>Home</div>
// <div className='main-menu-button gradient' onClick={() => handleClick('about')}>About Us</div>
// <div className='main-menu-button gradient' onClick={() => handleClick('contact')}>Contact Us</div>
// <div className='main-menu-button gradient' onClick={() => handleClick('/')}>Home</div>
// <div className='main-menu-button gradient' onClick={() => handleClick('about')}>About Us</div>
// <div className='main-menu-button gradient' onClick={() => handleClick('contact')}>Contact Us</div>
// <div className='main-menu-button gradient' onClick={() => handleClick('/')}>Home</div>
// <div className='main-menu-button gradient' onClick={() => handleClick('about')}>About Us</div>
// <div className='main-menu-button gradient' onClick={() => handleClick('contact')}>Contact Us</div>
// <div className='main-menu-button gradient' onClick={() => handleClick('/')}>Home</div>
// <div className='main-menu-button gradient' onClick={() => handleClick('about')}>About Us</div>
// <div className='main-menu-button gradient' onClick={() => handleClick('contact')}>Contact Us</div>
// <div className='main-menu-button gradient' onClick={() => handleClick('/')}>Home</div>
// <div className='main-menu-button gradient' onClick={() => handleClick('about')}>About Us</div>
// <div className='main-menu-button gradient' onClick={() => handleClick('contact')}>Contact Us</div>
// <div className='main-menu-button gradient' onClick={() => handleClick('/')}>Home</div>
// <div className='main-menu-button gradient' onClick={() => handleClick('about')}>About Us</div>
// <div className='main-menu-button gradient' onClick={() => handleClick('contact')}>Contact Us</div>
// <div className='main-menu-button gradient' onClick={() => handleClick('/')}>Home</div>
// <div className='main-menu-button gradient' onClick={() => handleClick('about')}>About Us</div>
// <div className='main-menu-button gradient' onClick={() => handleClick('contact')}>Contact Us</div>
