import React from 'react';

class Overview extends React.Component {
  render() {
    return(
      <div>
        <h1>
          Overview
        </h1>
        <div>
          Up To 15s is designed to help a student learn math facts for the basic operations including squares and cubes for 0 up to 15.
        </div>
        <div>
          The game has several options as well difficulties to help keep the game from becoming too boring too quickly.
        </div>
      </div>
    );
  }
}

export default Overview;
