import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from '../Header';

const MobileApps = () => {
  const location = useLocation();
  let returnObj = <Outlet />;
  if(location.pathname.length <= 8){
    returnObj = <h1>Mobile Applications</h1>;
  }
  return(
    <div>
      <div className='ui container'>
        <Header currentPage='mobile' />
      </div>
      <div>
        {returnObj}
      </div>
    </div>
  )
}

export default MobileApps;
