import React from 'react';
import { Outlet } from 'react-router-dom';
import MainMenu from '../menus/MainMenu/MainMenu';
import './Body.css';

export default function Body() {
	return(
		<div className='body-container'>
			<div className='body-sub-container'>
				<div className='body-menu-container'>
					<MainMenu />
				</div>
				<div className='body-outlet-container'>
					<Outlet />
				</div>
			</div>
		</div>
	);
}
