import React from 'react';

const Articles = () => {
  return (
    <div className='articles-container'>
      <div className='articles-title'>Articles</div>
      <div className='articles-body'></div>
      <div className='verticalSpacer20px'></div>
      <div className='verticalSpacer20px'></div>
      <div className='verticalSpacer20px'></div>
      <div className='verticalSpacer20px'></div>
    </div>
  );
}

export default Articles;
