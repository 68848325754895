import React from 'react';
import ScheduleButton from './sections/ScheduleButton';

const Consultation = () => {
  return (
    <div className='consultation-container'>
        <div className='consultation-title'>No Cost, No Obligation Consultation</div>
        <div className='verticalSpacer20px'>  </div>
        <div className='verticalSpacer20px'>  </div>
        <div className='consultation-message'>
          Please schedule a consultation for us to discuss your situation without
          any cost to you or obligation for you. <br />If you and I believe that it is
          prudent for us to enter into a coaching relationship, then at that time
          we will determine the committments. <br />You are welcome to click on the button to schedule a Zoom meeting, or you can email me, text me, or call me to schedule an in-person meeting.
        </div>
        <div className='verticalSpacer20px'>  </div>
        <div className='verticalSpacer20px'>  </div>
			<ScheduleButton />
        <div className='verticalSpacer20px'>  </div>
        <div className='verticalSpacer20px'>  </div>
        <div className='consultation-contact-info'>Phone: (254) 681-0745<br />Email: <a href='mailto:jmlatham@yahoo.com?subject=Financial Consultation'>jmlatham@yahoo.com</a></div>
        <div className='verticalSpacer20px'>  </div>
        <div className='verticalSpacer20px'>  </div>
    </div>
  );
}

export default Consultation;
