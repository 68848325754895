import React from 'react';
import { Link } from 'react-router-dom';

const PositiveResults = () => {
  return (
		<div className='positive-results-container'>
			<div className='positive-results-title'>Transformational Results of Financial Coaching:</div>
			<div className='positive-results-body'>
			<ul>
				<li key='2-1'>Get out of debt <div className='heart'>&#x2661;</div></li>
        <li key='2-2'>Reduce stress <div className='heart'>&#x2661;</div></li>
        <li key='2-3'>Take charge of money (tell money what to do) <div className='heart'>&#x2661;</div></li>
				<li key='2-4'>Improve relationships <div className='heart'>&#x2661;</div></li>
        <li key='2-5'>Gain financial peace of mind <div className='heart'>&#x2661;</div></li>
				<li key='2-6'>Gain financial power <div className='heart'>&#x2661;</div></li>
				<li key='2-7'>Gain more freedom <div className='heart'>&#x2661;</div></li>
        <li key='2-8'>Increase income <div className='heart'>&#x2661;</div></li>
        <li key='2-9'>Increase discipline <div className='heart'>&#x2661;</div></li>
        <li key='2-10'>Increase hope for the future <div className='heart'>&#x2661;</div></li>
				<li key='2-11'>Grow in knowledge, understanding, and wisdom <div className='heart'>&#x2661;</div></li>
				<li key='2-12'>Become even more generous than before <div className='heart'>&#x2661;</div></li>
				<li key='2-13'>Build a legacy to leave behind <div className='heart'>&#x2661;</div></li>
			</ul>
			</div>
		</div>
  );
}

export default PositiveResults;
