import React from 'react';
import Header from '../../../Header';
import Instructions from './Instructions';
import About from './About';
import Video from './Video';
import Overview from './Overview';

const UpTo15s = () => {
  return(
    <div>
      <div className='ui container'>
        <Header currentPage='upto15s' />
      </div>
      <div>
        <h1>Up To 15s</h1> - an educational math app to help learn math facts from 0 up to 15
      </div>
      <Overview />
      <Instructions />
      <Video />
      <About />
    </div>
  )
}

export default UpTo15s;
