import React from 'react';
import { Link } from 'react-router-dom';
import MainBodyContainer from './sections/BodyContainer';

export default function Coaching() {

	return(
		<>
    <MainBodyContainer />
		</>
  );
}
