import React, { useState, useEffect } from 'react'

const ContactInfo = ({ siteInfo, divClassContainer, divClassTitle, divClassPhone, divClassEmail, divClassFacebook }) => {
  const [phone, setPhone] = useState('(254) 681-0745');
  const [email, setEmail] = useState('marshall@jmlatham.com');
  const [faceBook, setFaceBook] = useState('facebook address');
  const parseSiteInfo = () => {
    try{
      if(siteInfo && Array.isArray(siteInfo)){
        siteInfo.forEach((info, index) => {
          if(info.group === "contactInfo"){
            if(info.item === "contactPhone"){
              setPhone(info.value);
            } else if(info.item === "contactEmail"){
              setEmail(info.value);
            } else if(info.item === "facebookAddress"){
              setFaceBook(info.value);
            }
          }
        });
      }
    } catch(err) {
      console.log("contactInfo err:", err);
    }
  }

  useEffect(() => {
    parseSiteInfo();
  }, [siteInfo]);

  return(
    <div className={divClassContainer}>
      <div className={divClassTitle}> Contact Me </div>
      <div className={divClassPhone}> Phone #: {phone}</div>
      <div className={divClassEmail}> Email Address: {email}</div>
      <div className={divClassFacebook}> Facebook: {faceBook}</div>
    </div>
  );
}

export default ContactInfo;
