import React from 'react';
import { Link } from 'react-router-dom';

const Values = () => {
  return (
		<div className='values-container'>
			<div className='values-text'>
				Balancing Budgets
				<br />
				- Learn to build and use a budget.
			</div>
			<div className='values-text'>
				Eliminating Debts
				<br />
				- Deal with paying off debts and handling debt collectors.
			</div>
			<div className='values-text'>
				Building Wealth
				<br />
				- Learn to make money work for you.
			</div>
		</div>
  );
}

export default Values;
