import React, { useState } from 'react'
import styles from './knows.module.css'

const Media = () => {
  const [hide, setHide] = useState(styles.hide);
  const [arrowDirection, setArrowDirection] = useState(styles.right);
  const handleClick = event => {
    if(hide == ""){
      setHide(styles.hide);
    } else {
      setHide("");
    }
    if(arrowDirection == styles.right){
      setArrowDirection(styles.down);
    } else {
      setArrowDirection(styles.right);
    }
  };

  return(
    <div className={styles.media}>
      <div className={[styles.title, styles.hand].join(' ')} onClick={handleClick}>KNOW Your Media <i className={[styles.arrow, arrowDirection].join(' ')}></i> </div>
      <div className={[styles.body, hide].join(' ')}>
        <ul className={styles.listTitle}>
          <li className={styles.listItem}>Power Point</li>
          <li className={styles.listItem}>Music</li>
          <li className={styles.listItem}>Videos</li>
          <li className={styles.listItem}>Flip Charts</li>
          <li className={styles.listItem}>Black/White Boards</li>
          <li className={styles.listItem}>Posters</li>
          <li className={styles.listItem}>Handouts</li>
          <li className={styles.listItem}>Internet and Virtual Presentations</li>
        </ul>
      </div>
    </div>
  )
}

export default Media
