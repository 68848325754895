import React from 'react'
import styles from './body.module.css'

const Coach = () => {
  return(
    <div className={styles.coachContainer}>
      Group Coaching: 10-week classes $2,000<br />
      Mondays 8:00 PM CT class 1<br />
      Wednesdays 10:00 AM CT class 2<br />
      -------------------------------<br />
      One-on-One: <br />
      3-month plan (12 sessions) $3,000<br />
      6-month plan (24 sessions) $4,800<br />
      2-hr presentations $750<br />
    </div>
  )
}

export default Coach;
