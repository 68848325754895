import React from 'react'
import Title from './title'
import Menu from '../components/menu'
import styles from './header.module.css'

const Header = () => {
  return(
    <div className={[styles.header, styles.sticky].join(" ")}>
      <Title />
      <br />
      <Menu
        divClassMenu={styles.menu}
        divClassMenuItem={styles.menuItem}
        divClassMenuItemContainer={styles.menuItemContainer}
      />
    </div>
  );
}

export default Header;
