import React from 'react';
import './Header.css';

export default function HeaderTitle({ title }) {
	// this is because the title could be passed as an array - for passing html tags
	let listItems = ''
	if(Array.isArray(title)){
	  listItems = title.map((str) =>
	    <span key={str.toString()}>
	      {str}
	    </span>
	  );
	} else {
		listItems = title;
	}

	return(
		<div className="header-title">
			{listItems}
		</div>
	);
}
