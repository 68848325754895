import React from 'react';

class Instructions extends React.Component {
  render() {
    return(
      <div className='section'>
        <div className='title'>
          Instructions
        </div>
        <div className='text'>
          This is how you play each of the games and navigate the application.
          This will include instructions for user management within the app.
        </div>
      </div>
    );
  }
}

export default Instructions;
