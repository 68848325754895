import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from '../../Header';
import SitSAMission from './SitSAMission';

const SitSA = () => {
  const location = useLocation();
  let returnObj = <Outlet />;
  if(location.pathname.length <= 13){
    returnObj = <div>
      <div>
        <h1>Stick in the Sand Apps</h1>
        <SitSAMission />
      </div>

    </div>;
  }

  return(
    <>
      <div className='ui container'>
        <Header currentPage='sitsa' />
      </div>
      {returnObj}
    </>
  )
}

export default SitSA;
