import React from 'react';
import { Link } from 'react-router-dom';
import ScheduleButton from './ScheduleButton';

const ThePlan = () => {
  return (
		<div className='the-plan-container'>
			<div className='the-plan-title'>The Plan</div>
			<div className='the-plan-body'>
				<ol>
					<li key='3-1'>Schedule a no-cost, no-obligation consultation</li>
					<li key='3-2'>Investigate the current situation</li>
					<li key='3-3'>Determine a coaching plan</li>
					<li key='3-4'>Layout the needs</li>
					<li key='3-5'>Calculate debts</li>
					<li key='3-6'>Build Budget</li>
					<li key='3-7'>Adjust Budget</li>
					<li key='3-8'>Increase Income</li>
					<li key='3-9'>Invest Earnings</li>
					<li key='3-10'>Build Wealth</li>
					<li key='3-11'>Earn Financial Freedom</li>
					<li key='3-12'>Experience Financial Peace</li>
				</ol>
			</div>
			<ScheduleButton />
		</div>
	);
}

export default ThePlan;
