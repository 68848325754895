import React from 'react';
import { Link } from 'react-router-dom';
import ScheduleButton from './ScheduleButton';

const NegativeResults = () => {
  return (
		<div className='negative-results-container'>
			<div className='negative-results-title'>If you don't make a change you will:</div>
			<div className='negative-results-body'>
			<ul>
				<li key='1-1'>Increase stress <div className='skull-crossbones'>&#x2620;</div></li>
				<li key='1-2'>Increase debt <div className='skull-crossbones'>&#x2620;</div></li>
				<li key='1-3'>Grow in despair <div className='skull-crossbones'>&#x2620;</div></li>
				<li key='1-4'>Continue to be a slave to money <div className='skull-crossbones'>&#x2620;</div></li>
				<li key='1-5'>Remain ignorant of financial knowledge and understanding <div className='skull-crossbones'>&#x2620;</div></li>
				<li key='1-6'>Never get unstuck <div className='skull-crossbones'>&#x2620;</div></li>
				<li key='1-7'>Leave behind burdens for future generations to bear <div className='skull-crossbones'>&#x2620;</div></li>
				<li key='1-8'>Lose the blessing of helping others <div className='skull-crossbones'>&#x2620;</div></li>
			</ul>
			</div>
			<ScheduleButton />
		</div>
	);
}

export default NegativeResults;
