import React from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';

export default function Coaching() {
  const navigate = useNavigate();
	const location = useLocation();

  const handleClick = (path) => {
    navigate(path);
  }

	let returnObj = <div><Outlet /></div>
	if(location.pathname === '/coaching'){
		returnObj = <div style={{display:'flex', flexDirection:'row'}}>
			<div style={{margin:'3px',textDecoration:'underline', cursor:'pointer'}} onClick={() => handleClick('/coaching/financial')}>Financial</div>
			<div style={{margin:'3px',textDecoration:'underline', cursor:'pointer'}} onClick={() => handleClick('/coaching/fiveknows')}>Public Speaking</div>
		</div>;
	}


	return(
		<div className='coaching-container'>
			{returnObj}
		</div>
	);
}
